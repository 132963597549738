<template>
  <v-chip
    v-bind="$attrs"
    class="flex-shrink-0 font-weight-bold px-1"
    :class="classes"
    :color="color"
    label
    :text-color="textColor"
    variant="flat"
  >
    <slot>
      {{ orderType ? orderType.name : '' }}
    </slot>
  </v-chip>
</template>

<script setup lang="ts">
export interface Props {
  border?: boolean
  orderType?: { value: number, name?: string }
}

const props = withDefaults(defineProps<Props>(), {
  border: false,
  orderType: undefined,
})

const cybColor = { color: 'light-teal', textColor: 'text-interactive' }
const diyColor = { color: 'light-grey', textColor: 'text-secondary' }
const rfqColor = { color: 'background', textColor: 'text-primary' }
const colorMap: { [key: number]: { color: string, textColor: string } } = {
  1: rfqColor,
  2: diyColor,
  3: cybColor,
  4: { color: 'mid-dark-grey', textColor: 'text-white' },
  5: rfqColor,
  6: diyColor,
  7: cybColor,
  8: { color: 'indigo-lighten-1', textColor: 'text-blue-grey-lighten-5' },
  9: { color: 'green-lighten-4', textColor: 'text-green-darken-3' },
}
const color = computed(() => colorMap[props.orderType?.value || 0]?.color || 'background')
const textColor = computed(() => colorMap[props.orderType?.value || 0]?.textColor || 'text-dark-grey')

const classes = computed(() => {
  const cls: { [key: string]: boolean } = { 'order-type-border': props.border }

  cls[textColor.value] = true

  return cls
})
</script>

<style lang="scss" scoped>
.order-type-border {
  border: 2px solid currentColor;
}
</style>
